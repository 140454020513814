<template>
    <BT-Blade-Item
        bladeName="notification-configuration"
        :bladesData="bladesData"
        navigation="stock-notification-configurations">
        <template v-slot="{ item, data }">

            <BT-Field-String
                v-model="item.externalPartyName"
                label="Party Name"
                :isEditing="data.isNew" />

            <BT-Field-String
                v-model="item.configurationName"
                label="Configuration Name"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-String
                v-model="item.notificationGroup"
                label="Notification Group"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-String
                v-model="item.webhookURL"
                label="Webhook URL"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-String
                v-model="item.status"
                label="Status"
                :isEditing="data.isEditing" />

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Notification-Configuration-Blade',
    components: {
        // BTBladeExpansionLinks: () => import('~components/BT-Blade-Expansion-Links.vue')
    },
    data: function() {
        return {
            // panelV: 0
        }
    },
    props: {
        bladesData: null
    }
}
</script>